<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 三品一标数量 -->
      <div class="content-item products-quantity">
        <div class="content-title">
          <span>三品一标数量</span>
        </div>
        <div
          class="content-charts"
          v-loading="!products.list.length"
          element-loading-background="transparent"
        >
          <div
            id="products-quantity-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 湛渔产品 -->
      <div
        class="content-item fish-products"
        v-loading="!certificatePicture.length && !productImgs.length"
        element-loading-background="transparent"
      >
        <div class="content-title">
          <span>湛渔产品</span>
        </div>
        <div class="content-charts">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(ele, idx) in productImgs" :key="idx">
              <div class="item-wrap">
                <img :src="ele.url" alt />
                <span>{{ ele.name }}</span>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <swiper
            style="margin-top: 0.4rem"
            :class="['swiper', {'three-data': certificatePicture.length<=3}]"
            :options="swiperOption"
          >
            <swiper-slide v-for="(ele, idx) in certificatePicture" :key="idx">
              <div class="item-wrap company-item">
                <img :src="ele.url" alt />
                <span>{{ ele.name }}</span>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 园区宣传片 -->
      <div class="content-item publicity-video">
        <div class="content-title">
          <span>园区宣传片</span>
        </div>
        <div class="content-charts">
          <div class="video-box">
            <video
              v-if="infoData.videoUrl"
              style="min-height: 100%; min-width: 100%; object-fit: fill"
              :src="infoData.videoUrl"
              autoplay
              loop
              controls
              muted
            ></video>
            <img
              v-else
              src="../../../assets/img/large-screen/img-yu-wang.png"
              alt
            />
          </div>
        </div>
      </div>
      <!-- 园区新闻 -->
      <div class="content-item park-news">
        <div class="content-title">
          <span>园区新闻</span>
        </div>
        <div class="content-charts">
          <div class="ls-wrap" @click="dialogFormVisible = true">
            <div
              v-for="(ele, idx) in articleTop3"
              :key="idx"
              class="ls-item"
              @click="actIdx = idx"
            >
              <div class="img">
                <img :src="ele.url" alt />
              </div>
              <div class="txt-wrap">
                <p>{{ ele.title }}</p>
                <span>{{ ele.createTime.split(' ')[0] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>
    <el-dialog
      :title="articleTop3[actIdx] && articleTop3[actIdx].title"
      width="882px"
      top="14vh"
      :visible.sync="dialogFormVisible"
    >
      <div class="module">
        <div
          class="module-content dialog-content"
          v-html="articleTop3[actIdx] && articleTop3[actIdx].content"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog } from 'element-ui'
Vue.use(Dialog)

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
// import videoDemo from '../../utils/videoDemo/EZUIKitJs.vue'

import * as $echarts from 'echarts'
export default {
  name: 'zyu-brand',
  components: {
    swiper,
    swiperSlide,
  },
  inject: ['app'],
  data() {
    return {
      infoData: {},
      dialogFormVisible: false,
      actIdx: 0,
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: false,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 280,
          modifier: 1,
          slideShadows: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },
        // centeredSlides: true,
        // slidesPerView: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      // 三品一标数量
      products: {
        idName: 'products-quantity-chart',
        list: [],
      },
      // 湛渔产品
      productImgs: [],
      certificatePicture: [],
      // 地图详情数据
      areaInfo: {},

      // 前三文章
      articleTop3: '',
    }
  },
  computed: {},
  methods: {
    // 玫瑰图
    productsQuantityChart() {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(this.products.idName))
      let list = this.products.list
      list.forEach(function (item) {
        item.value = +item.value
      })
      let color1 = [
        '#5442FF',
        '#EBA447',
        '#4898FB',
        '#0CCCCE',
        '#5DE9C4',
        '#E654EB',
      ]
      let option = {
        color: color1,
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}个 ({d}%)',
        },
        polar: {},
        angleAxis: {
          interval: 1,
          type: 'category',
          data: [],
          z: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#0B4A6B',
              width: 1,
              type: 'solid',
            },
          },
          axisLabel: {
            interval: 0,
            show: true,
            color: '#0B4A6B',
            margin: fontHandle(8),
            fontSize: fontHandle(16),
          },
        },
        radiusAxis: {
          show: false,
        },
        calculable: true,
        series: [
          {
            stack: 'a',
            type: 'pie',
            radius: ['10%', '90%'],
            roseType: 'area',
            zlevel: 10,
            label: {
              normal: {
                show: true,
                formatter: '\n{font|{b}}\n{font2|{c}}',
                rich: {
                  font: {
                    fontSize: fontHandle(14),
                    color: '#D1E2F4',
                  },
                  font2: {
                    fontSize: fontHandle(28),
                    padding: [5, 0],
                    color: '#2AEDFF',
                    fontWeight: 'bold',
                  },
                },
                borderRadius: fontHandle(20),
                position: 'outside',
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {},
            labelLine: {
              normal: {
                show: true,
              },
              emphasis: {
                show: false,
              },
            },
            data: list,
          },
        ],
      }
      chart.setOption(option)
    },
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
    this.$axios('/zhanjiang-dataview/viewZhanFishBrand/queryInfo').then(
      (res) => {
        this.products.list = res.data.pieChartList
        this.infoData = res.data
        this.productsQuantityChart()
      }
    )
    this.$axios('/zhanjiang-dataview/viewZhanFishBrand/queryArticleTop3')
      .then((res) => {
        this.articleTop3 = res.data
      })
      .catch(() => {})
    this.$axios(
      '/zhanjiang-dataview/viewZhanFishBrand/queryCertificatePictureList'
    )
      .then((res) => {
        this.certificatePicture = res.data || []
      })
      .catch(() => {})
    this.$axios(
      '/zhanjiang-dataview/viewZhanFishBrand/queryProductionPictureList'
    )
      .then((res) => {
        this.productImgs = res.data
      })
      .catch(() => {})
  },
  created() {},
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './zyu-brand.less';

</style>
